@use "sass:map";
@import "colors";

@import "bulma/sass/utilities/_all";

@import "bulma/sass/base/_all";

@import "bulma/sass/elements/container";
@import "bulma/sass/elements/content";

@import "bulma/sass/form/shared";
@import "bulma/sass/form/input-textarea";
@import "bulma/sass/form/tools";

@import "bulma/sass/components/card";

@import "bulma/sass/grid/columns";

html {
	position: relative;
	min-height: 100% !important;
	width: 100vw;
	overflow-y: auto;
	overflow-x: hidden !important;
}

body {
	margin: 0;
	padding-right: 0 !important;
	min-height: 100% !important;
	overflow-x: hidden !important;
	overflow-y: auto !important;
	color: black;
	font-family: 'Roboto', sans-serif;
}

#root {
	display: flex;
	flex-direction: column;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
	scrollbar-width: auto;
	scrollbar-color: map.get($theme, "greyDark") white;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 13px;
	height: 13px;
}

*::-webkit-scrollbar-track {
	background: white;
}

*::-webkit-scrollbar-thumb {
	background-color: map.get($theme, "greyDark");
	border-radius: 10px;
	border: 2px solid white;
}

.-sort-asc,
.-sort-desc {
	padding-right: 20px !important;
	box-shadow: none !important;
}

.-sort-asc::before {
	content: "\25B2";
	position: absolute;
	right: 1.2rem;
	top: 0.65rem;
}

.-sort-desc::before {
	content: "\25BC";
	position: absolute;
	right: 1.2rem;
	top: 0.65rem;
}

.no-before::before {
	content: none;
}

.no-before {
	padding-top: 5px !important;
	padding-bottom: 0 !important;
	padding-right: 5px !important;
}

.ReactTable .rt-th {
	overflow: visible;
}

.-noborder {
	border: none !important;
}

@media screen and (min-width: 1024px) {
	.ReactTable {
		max-width: 960px !important;
	}
}

@media screen and (min-width: 1216px) {
	.ReactTable {
		max-width: 1152px !important;
	}
}

@media screen and (min-width: 1408px) {
	.ReactTable {
		max-width: 1344px !important;
	}
}

.no-pad {
	max-width: none;
	margin-left: 3%;
	margin-right: 3%;
}

.-previous,
.-center,
.-next {
	flex: unset !important;
	align-self: center !important;
}

.-pagination {
	justify-content: center !important;
	margin: unset !important;
}

.ReactTable.-striped {
	.rt-tr {
		&.-even {
			background-color: map.get($theme, "secondary") !important;

			&:hover {
				background-color: map.get($theme, "secondary") !important;
			}
		}

		&.-odd {
			background-color: white !important;

			&:hover {
				background-color: map.get($theme, "secondary") !important;
			}
		}
	}

	.rt-tr-group {
		border: none !important;
	}

	.rt-thead {
		border: none !important;
		background-color: map.get($theme, "secondary") !important;
	}
}

text.selector-text {
	fill: black !important;
}

.header-container {
	margin: 0 auto !important;
	width: 100%;
	height: 100%;
	padding-left: 0 !important;
	padding-right: 0 !important;
	min-height: auto;
}

// @media screen and (min-width: 1024px) {
// 	.header-container {
// 		max-width: 960px;
// 	}
// }

// @media screen and (min-width: 1216px) {
// 	.header-container {
// 		max-width: 1152px;
// 	}
// }

// @media screen and (min-width: 1408px) {
// 	.header-container {
// 		max-width: 1844px;
// 	}
// }

.small-icon span svg {
	padding-left: 0.1em;
	padding-right: 0.1em;
}

#collapse::before {
	border-bottom: 7px solid white;
}

#collapse::after {
	border-bottom: unset;
}

#expand::before {
	border-top: 7px solid white;
}

#expand::after {
	border-top: unset;
}

.disabled-link {
	pointer-events: none;
}

.no-margin-bottom {
	margin-bottom: 0px !important;
}

#due_date {
	height: 1.5rem;
}

.filepond--wrapper {
	display: none;
}

.suggestions-dropdown {
	z-index: 1;
	max-height: 100%;
	overflow: auto;
}

.smooth-dnd-ghost {
	>div {
		cursor: grabbing !important;
	}
}

.marked-text {
	background-color: map.get($theme, "light");
}

.MuiOutlinedInput-notchedOutline {
	border: 0px !important;
	color: inherit !important;
}

.MuiInputLabel-root {
	color: inherit !important;
}

.MuiOutlinedInput-root {
	color: inherit !important;
}

.MuiIconButton-root {
	color: inherit !important;
}

.MuiInputAdornment-positionEnd {
	color: inherit !important;
}

.parameter-input {
	display: flex;
	align-items: center !important;
	justify-content: center !important;
}

.parameter-input .bp5-input-group .bp5-input {
	background: none;
	border: 2px solid #3c9764;
	color: white;
	font-size: 16px;
}

.parameter-input .bp5-button-group .bp5-button {
	background: #3c9764;
	border-color: #3c9764;
}

.parameter-input .bp5-button-group {
	display: flex;
}

.parameter-input .bp5-button-group .bp5-button .bp5-icon {
	color: white;
}

.bp5-popover-open {
	z-index: 2;
}

.react-flow__edge-interaction {
	stroke-opacity: 1 !important;
	stroke: white !important;
	stroke-width: 2px;
}

.react-flow__edge-path {
	stroke: white !important;
	stroke-width: 2px !important;
	stroke-linecap: round;
	stroke-linejoin: round;
	fill: none !important;
}

.react-flow__edge:hover {
	stroke: #ff5733;
	stroke-width: 3px;
}

.react-flow__node {
	height: auto !important;
}

.react-flow__node-custom {
	height: auto !important;
}

// .react-flow__node:hover {
// 	border: 2px solid #1e90ff !important;
// 	background-color: rgba(30, 144, 255, 0.2) !important;
// }

.react-flow__node.selected {
	border: 2px solid #ff5733 !important;
	background-color: rgba(255, 87, 51, 0.2) !important;
}

.react-flow__edge.selected .react-flow__edge-path {
	stroke: #ff5733 !important;
	stroke-width: 4px !important;
	stroke-dasharray: 5, 5;
	animation: dash 1s linear infinite;
}

.react-flow__edge-interaction {
	stroke-opacity: 1 !important;
	stroke: transparent !important;
	stroke-width: 8px;
}

@keyframes dash {
	to {
		stroke-dashoffset: -20;
	}
}

.react-numeric-input>b {
	background: white !important;
	margin-top: 1px;
	margin-bottom: 1px;
	height: 30%;
	cursor: pointer !important;
}

.react-numeric-input>b:first-of-type>i {
	border-width: 0px 0.5ex 0.5ex !important;
	margin: -0.3ex 0px 0px -0.46ex !important;
}

.react-numeric-input>b:last-of-type>i {
	border-width: 0.5ex 0.5ex 0px !important;
	margin: -0.1ex 0px 0px -0.46ex !important;
}

.node-error {
	animation: pulse-border 1.5s infinite;
}

@keyframes pulse-border {
	0% {
		border: 5px solid #f63719;
	}

	50% {
		border: 5px solid rgba(246, 55, 25, 0.5);
	}

	100% {
		border: 5px solid #f63719;
	}
}

@keyframes linearAlarmAnimation {
	from {
		height: 0px;
		filter: blur(2.5px);
	}

	to {
		height: 8px;
		filter: blur(5px);
	}
}

@keyframes areaAlarmAnimation {
	50% {
		opacity: 0;
	}
}

.simulationPopupClose {
	background-color: rgba(0, 0, 0, 0.5);
	width: 30px;
	height: 30px;
}
.simulationPopupClose:hover {
	background-color: rgba(0, 0, 0, 0.7);
}
