/* 
  ** Sidebar **
*/

.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
  max-height: calc(100vh - 100px); /* Adjust this as needed */
  overflow-y: auto; /* Enable vertical scrolling */
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .dndnode-wrapper {
  display: inline-flex; 
  padding: 3px;
  border-radius: 5px;
  background: linear-gradient(45deg, #e92a67, #2a8af6); 
  margin-bottom: 10px; 
  width: 49%; 
}

.dndflow .dndnode {
  height: 30px;
  padding: 4px;
  background-color: #141212; /* Inner background color */
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  width: 100%; /* Take full width of the wrapper */
  position: relative; /* Ensures it's on top of the gradient */
}


.dndnode-container {
    display: flex;
    flex-wrap: wrap;
}

.dndnode-wrapper:nth-child(odd) {
    margin-right: 3px;
}

.dndflow .dndnode.static {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: pointer;
}

.dndflow .dndnode.static:hover  {
  background: linear-gradient(45deg, #3d3939, #141212);
} 


.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}
.dndflow .dndnode-wrapper-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 5px;
  background: linear-gradient(45deg, #e92a67, #2a8af6);
  margin-bottom: 10px;
  width: 49%;
  height: 30px; /* Match the height of your other nodes */
  position: relative; /* Necessary for absolute positioning inside */
  z-index: 1; /* Ensure it's above other content */
}

.dndflow .icon-plus {
  font-size: 24px; /* Adjust size as needed */
  color: white;
  user-select: none; /* Prevent text selection */
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}


/* 
  ** Custom Styling **
*/

.react-flow {
  --bg-color: rgb(17, 17, 17);
  --text-color: rgb(243, 244, 246);
  --node-border-radius: 10px;
  --node-box-shadow: 10px 0 15px rgba(42, 138, 246, 0.3), -10px 0 15px rgba(233, 42, 103, 0.3);
  background-color: var(--bg-color);
  color: var(--text-color);
}

.react-flow__node {
  border-radius: var(--node-border-radius);
  display: flex;
  height: 70px;
  min-width: 150px;
  font-family: 'Fira Mono', Monospace;
  font-weight: 500;
  letter-spacing: -0.2px;
  box-shadow: var(--node-box-shadow);
}

.react-flow__node .wrapper {
  overflow: hidden;
  display: flex;
  padding: 2px;
  position: relative;
  border-radius: var(--node-border-radius);
  flex-grow: 1;
}

.gradient:before {
  content: '';
  position: absolute;
  padding-bottom: calc(100% * 1.41421356237);
  width: calc(100% * 1.41421356237);
  background: conic-gradient(
    from -160deg at 50% 50%,
    #e92a67 0deg,
    #a853ba 120deg,
    #2a8af6 240deg,
    #e92a67 360deg
  );
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
}

.react-flow__node.selected .wrapper.gradient:before {
  content: '';
  background: conic-gradient(
    from -160deg at 50% 50%,
    #e92a67 0deg,
    #a853ba 120deg,
    #2a8af6 240deg,
    rgba(42, 138, 246, 0) 360deg
  );
  animation: spinner 4s linear infinite;
  transform: translate(-50%, -50%) rotate(0deg);
  z-index: -1;
}

@keyframes spinner {
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

.sensor-form {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  padding: 15px;
  border-radius: 15px;
  background-color: #111111;
  /* ... other styles ... */
}

.react-flow__node .sensor-form button{
  border: 0px;
  border-radius: 20px;
  padding: 3px 4px;
  margin-top: 15px;
  background-color: transparent;
}

.react-flow__node .sensor-form button span{
  border: 0px;
  border-radius: 15px;
  padding: 7px 18px;
  background-color: white;
}

.sensor-form input:nth-child(2){
  width: 163px;
}

.react-flow__node .inner {
  background: var(--bg-color);
  padding: 16px 20px;
  border-radius: var(--node-border-radius);
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  position: relative;
}

.react-flow__node .inner.grouped{
  justify-content: start;
  text-align: center;
}

.react-flow__node .inner.grouped .body{
  justify-content: center;
}

.react-flow__node .icon {
  margin-right: 8px;
}

.react-flow__node .body {
  display: flex;
}

.react-flow__node .title {
  font-size: 16px;
  margin-bottom: 2px;
  line-height: 1.2;
}

.react-flow__node .communication_protocols {
  font-size: 12px;
  color: #777;
}

.react-flow__node .cloud {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  right: 0;
  position: absolute;
  top: 0;
  transform: translate(50%, -50%);
  display: flex;
  transform-origin: center center;
  padding: 2px;
  overflow: hidden;
  box-shadow: var(--node-box-shadow);
  z-index: 1;
}

.react-flow__node .cloud div {
  background-color: var(--bg-color);
  flex-grow: 1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.react-flow__node .delete {
  border-radius: 100%;
  width: 30px;
  height: 30px;
  right: 0;
  position: absolute;
  top: 0;
  transform: translate(-70%, -50%);
  display: flex;
  transform-origin: center center;
  padding: 2px;
  overflow: hidden;
  box-shadow: var(--node-box-shadow);
  z-index: 1;
}

.react-flow__node .delete div {
  background-color: var(--bg-color);
  flex-grow: 1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.react-flow__handle {
  border: 2px solid #2a8af6;
  background: #2a8af6;
}

.react-flow__handle.source {
  right: -18px;
}

.react-flow__handle.target {
  left: -18px;
}

.react-flow__node:focus {
  outline: none;
}

.react-flow__edge .react-flow__edge-path {
  stroke: url(#edge-gradient);
  stroke-width: 2;
  stroke-opacity: 0.75;
}

.react-flow__controls button {
  background-color: var(--bg-color);
  color: var(--text-color);
  border: 1px solid #95679e;
  border-bottom: none;
}

.react-flow__controls button:hover {
  background-color: rgb(37, 37, 37);
}

.react-flow__controls button:first-child {
  border-radius: 5px 5px 0 0;
}

.react-flow__controls button:last-child {
  border-bottom: 1px solid #95679e;
  border-radius: 0 0 5px 5px;
}

.react-flow__controls button path {
  fill: var(--text-color);
}

.react-flow__attribution {
  background: rgba(200, 200, 200, 0.2);
}

.react-flow__attribution {
  color: #95679e;
}

/* edit button */

.delete-button {
  background: none;
  color: white;
  border: none;
  padding: 0;
  cursor: pointer;
}

/* edit button */

.edit-button {
  background: none;
  color: white;
  border: none;
  padding: 0;
  cursor: pointer;
}

/* close edge button */

.edgebutton {
  width: 20px;
  height: 20px;
  background: #e6e6e6;
  border: 1px solid #e6e6e6;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(255, 255, 255, 0.5);
}

/* form slider*/
.freq-range {
  margin-left: 5px;
  margin-top: 10px;
}

.slider {
  width: 80%;
  height: 5px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  background: #2a8af6;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}


/* save button */
.save-button{
  margin-right: 15px;
}
/* form automation*/
.automation-form{
  position: absolute;
  top: 0;
  left: 50%;
  width: 400%;
  transform: translateX(-50%);
  z-index: 9999;
  padding: 15px;
  border-radius: 15px;
  background-color: #1e1e1e;
  color: white;
}

.form{
  position: absolute;
  top: 0;
  left: 50%;
  width: 150%;
  transform: translateX(-50%);
  z-index: 9999;
  padding: 15px;
  border-radius: 15px;
  background-color: #1e1e1e;
  color: white;
}